import './index.css'
import { Metaballs } from './components/Metaballs'

const init = () => {
	const metaballs = new Metaballs()

	const submit = document.querySelector('button')
	const form = document.querySelector('form')
	submit?.addEventListener('click', () => {
		if (form?.checkValidity()) {
			submit.textContent = 'sending'
		}
	})

	const update = (time: number) => {
		window.requestAnimationFrame(update)
		metaballs.update(time)
	}

	const resize = () => {
		metaballs.resize()
	}

	window.addEventListener('resize', resize)
	window.requestAnimationFrame(update)
	resize()
}

document.readyState === 'loading' ? document.addEventListener('DOMContentLoaded', () => init()) : init()
