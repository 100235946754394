import vertexShader from './metaballs.vert'
import fragmentShader from './metaballs.frag'
import { Color, Mesh, PerspectiveCamera, PlaneGeometry, Scene, ShaderMaterial, Vector2, WebGLRenderer } from 'three'

const delay = (ms: number) => new Promise((r) => setTimeout(r, ms))

const SIZE = 1.2
const DELAY = 0.05
const NUM = 5
const SPEED = 0.002

class Circle {
	private velocity: Vector2
	private maxSize = 0

	public spawned = false
	public position: Vector2
	public size = 0

	constructor() {
		this.position = new Vector2()
		this.velocity = new Vector2()
	}

	spawn(pos: Vector2, resolution: Vector2) {
		const speed = Math.min(resolution.x, resolution.y) * SPEED
		this.position.copy(pos)
		this.velocity.x = (Math.round(Math.random()) * 2 - 1) * (Math.random() + 0.5) * speed
		this.velocity.y = (Math.round(Math.random()) * 2 - 1) * (Math.random() + 0.5) * speed
		this.size = 0
		this.maxSize = Math.random() * 0.3 + 0.05
		this.spawned = true
	}

	update() {
		this.size = Math.min(this.size + 0.005, this.maxSize)
		this.velocity.x *= 0.94 + 0.06
		this.velocity.y *= 0.94 + 0.06
		this.position.x += this.velocity.x
		this.position.y += this.velocity.y
	}
}

export class Metaballs {
	private readonly renderer: WebGLRenderer
	private readonly mesh: Mesh
	private readonly circles: Circle[] = []
	private readonly container: HTMLDivElement
	private readonly material: ShaderMaterial
	private readonly scene = new Scene()
	private readonly camera = new PerspectiveCamera()
	private readonly size = SIZE
	private resolution = new Vector2()
	private pointer = new Vector2()
	private pointerTarget = new Vector2()
	private minSize = 0
	private readonly mq: MediaQueryList

	constructor() {
		this.renderer = new WebGLRenderer({ alpha: true, antialias: false })
		this.container = document.querySelector('.container') as HTMLDivElement
		this.container.appendChild(this.renderer.domElement)

		this.mq = window.matchMedia('(min-width:768px)')

		document.addEventListener('mousemove', this.onMouseMove.bind(this))
		// document.addEventListener('touchstart', this.onTouchStart.bind(this))
		// document.addEventListener('touchmove', this.onTouchMove.bind(this))

		const circles = []
		const sizes = []

		for (let i = 0; i < NUM; i++) {
			this.circles.push(new Circle())
			circles.push(new Vector2(-5000, -5000))
			sizes.push(0)
		}

		this.material = new ShaderMaterial({
			vertexShader,
			fragmentShader,
			transparent: true,
			depthTest: false,
			depthWrite: false,
			uniforms: {
				pointer: { value: this.pointer },
				circles: { value: circles },
				size: { value: this.size },
				sizes: { value: sizes },
				resolution: { value: new Vector2() },
				minSize: { value: 500 },
				color: { value: new Color(151 / 255, 71 / 255, 255 / 255) },
				time: { value: 0 }
			}
		})

		this.mesh = new Mesh(new PlaneGeometry(2, 2), this.material)
		this.scene.add(this.mesh)
		this.resize()

		this.pointerTarget.set(this.resolution.x * 0.5, this.resolution.y * 0.5)
		this.pointer.copy(this.pointerTarget)

		this.init()
	}

	async init() {
		for (let i = 0; i < NUM; i++) {
			await delay(150 * i)
			this.circles[i].spawn(new Vector2(this.resolution.x * 0.5, this.resolution.y * 0.5), this.resolution)
		}
	}

	onTouchStart(event: TouchEvent) {
		const { clientX, clientY } = event.touches[0]
		this.pointerTarget.set(clientX, this.resolution.y - clientY)
	}

	onMouseMove(event: MouseEvent) {
		const { clientX, clientY } = event
		this.pointerTarget.set(clientX, this.resolution.y - clientY)
	}

	onTouchMove(event: TouchEvent) {
		const { clientX, clientY } = event.touches[0]
		this.pointerTarget.set(clientX, this.resolution.y - clientY)
	}

	resize() {
		const { width, height } = this.container.getBoundingClientRect()
		this.resolution.set(width, height)
		this.minSize = Math.min(this.resolution.x, this.resolution.y)
		this.renderer.setSize(this.resolution.x, this.resolution.y)
		this.material.uniforms.resolution.value.copy(this.resolution)
		this.material.uniforms.minSize.value = this.minSize
	}

	update(time: number) {
		this.pointer.x += Math.sin(time * 0.001) * this.resolution.x * 0.001
		this.pointer.y += Math.cos(time * 0.0015) * this.resolution.x * 0.001

		this.pointer.x += (this.pointerTarget.x - this.pointer.x) * DELAY
		this.pointer.y += (this.pointerTarget.y - this.pointer.y) * DELAY

		this.circles.forEach((circle, index) => {
			if (!circle.spawned) return

			circle.update()
			const width = circle.size * this.resolution.x
			const height = circle.size * this.resolution.y

			if (
				circle.position.x - width > this.resolution.x ||
				circle.position.x + width < 0 ||
				circle.position.y - height > this.resolution.y ||
				circle.position.y + height < 0
			) {
				circle.spawn(this.pointer, this.resolution)
			}

			this.material.uniforms.circles.value[index].copy(circle.position)
			this.material.uniforms.sizes.value[index] = circle.size
		})

		this.material.uniforms.size.value = this.size + Math.sin(time * 0.001) * 0.05
		this.renderer.render(this.scene, this.camera)
	}
}
